import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  backgroundColors: {
    primary: "white",
    secondary: "#222B37",
    lightAlt: "#F6F8FA",
  },
  colors: {
    primary: "#f9566e",
    secondary: "#ffaa76",
    tertiary: "#ef1d96",
    border: "#EAE8E4",
  },
  textColors: {
    primary: "#5B5F73",
    secondary: "#999FAF",
    tertiary: "#9A9FAE",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
