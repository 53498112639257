import logo from "../images/bettermedics-logo.png";

import React, { useState } from "react";
import styled from "styled-components";

import Footer from "../components/Footer";

const InteriorLayout = ({ children }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const isWebView = queryParameters.get("webview");

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Container>
      {isWebView ? null : (
        <Header>
          <a href="/">
            <Logo src={logo} />
          </a>
          <MobileBtn open={menuOpen} onClick={() => setMenuOpen(!menuOpen)}>
            <svg
              aria-hidden="true"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
            >
              <g>
                <path d="M5 13h90v14H5z" />
                <path d="M5 43h90v14H5z" />
                <path d="M5 73h90v14H5z" />
              </g>
            </svg>
          </MobileBtn>
          <AuxMenu open={menuOpen}>
            <a href="/about">About Us</a>
            <a href="/careers">Careers</a>
            <a href="/contact">Contact Us</a>
          </AuxMenu>
        </Header>
      )}

      <Content>{children}</Content>
      {isWebView ? null : <Footer mode="internal" />}
    </Container>
  );
};

const Content = styled.div`
  flex: 1;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid #eee;
  background-color: white;
`;

const AuxMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;

  a {
    color: ${(props) => props.theme.textColors.primary};
    font-size: 15px;
    text-decoration: none;
    font-weight: bold;
    padding: 6px 10px;
    margin: 0 10px;
    letter-spacing: 0.5px;

    &:hover {
      color: ${(props) => props.theme.colors.secondary};
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 992px) {
    position: fixed;
    flex-direction: column;
    top: 85px;
    left: 0;
    right: 0;
    z-index: 10;
    display: ${(props) => (props.open ? "block" : "none")};

    background-color: ${(props) => props.theme.backgroundColors.lightAlt};
    border-top: 2px solid ${(props) => props.theme.colors.border};

    box-shadow: 0 10px 10px rgba(28, 38, 83, 0.08);
    border-radius: 0 0 10px 10px;
    padding: 5px 0;

    a {
      display: block;
      text-align: center;
      border-top: 1px dashed ${(props) => props.theme.colors.border};
      margin: 0;
      padding: 20px;

      &:first-child {
        border-top: none;
      }
    }
  }
`;

const Logo = styled.img`
  height: 36px;
  transform: translateZ(0);

  @media only screen and (max-width: 550px) {
    width: 200px;
  }
`;

const MobileBtn = styled.button`
  display: none;
  border: none;
  border-radius: 0;
  background: transparent;
  color: ${(props) => props.theme.textColors.primary};
  cursor: pointer;
  padding: 1em 1.5em;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;

  font-size: 0.875em;
  padding: 1em;
  margin-left: auto;

  svg {
    display: inline-block;
    fill: currentColor;
    height: 1em;
    width: 1em;
    vertical-align: middle;
    position: relative; /* Align more nicely with capital letters */
    top: -0.0625em;

    transform: scale(1.75);
  }

  g {
    opacity: 1;
    transform: rotate(0) translateY(0) translateX(0);
    transform-origin: 1em 1em;
  }

  path {
    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;

    &:first-child {
      transform-origin: 1em 2.5em;
    }
    &:last-child {
      transform-origin: 1em 4.5em;
    }
  }

  ${({ open }) =>
    open &&
    `
    path {
      opacity: 0;
      &:first-child {
        transform: rotate(45deg) translateY(0) translateX(0);
        opacity: 1;
      }
      &:last-child {
        transform: rotate(-45deg) translateY(0em) translateX(0em);
        opacity: 1;
      }
    }
  `}

  @media only screen and (max-width: 992px) {
    display: inline-block;
  }
`;

export default InteriorLayout;
