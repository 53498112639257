import React from "react";
import styled from "styled-components";

const Footer = ({ mode = "internal" }) => (
  <Container mode={mode}>
    <div>
      <Copyright>{`© ${new Date().getFullYear()} BetterMedics, Inc.`}</Copyright>
      <Links>
        <a href="/privacy">Privacy Policy</a>
        <span></span>
        <a href="/terms">Terms of Use</a>
        <span></span>
        <a href="/privacy-practices">Privacy Practices</a>
        <span></span>
        <a href="/notice-of-non-discrimination">Notice of Non-Discrimination</a>
      </Links>
    </div>
  </Container>
);

const Copyright = styled.p``;

const Links = styled.div`
  display: flex;

  span {
    margin: 0 14px;
    width: 1px;
    background-color: ${(props) => props.theme.textColors.tertiary};
    opacity: 0.3;
  }

  @media only screen and (max-width: 800px) {
    display: block;
    flex: 1;

    span {
      display: none !important;
    }
  }
`;

const Container = styled.footer`
  position: fixed;
  bottom: 14px;
  left: 0;
  right: 0;
  z-index: 20;
  text-align: center;

  > div {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 30px;
    padding: 14px 30px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  }

  p {
    margin: 0 40px 0 0;
  }

  p,
  a,
  span {
    display: inline-block;
    color: ${(props) => props.theme.textColors.tertiary};
    font-size: 11px;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  @media only screen and (max-width: 800px) {
    position: relative;
    border-top: 1px solid;
    border-color: ${(props) => props.theme.colors.border};

    p,
    a {
      display: block;
    }

    a {
      margin-bottom: 16px;
      font-size: 15px;
    }

    > div {
      display: flex;
      border-radius: 0;
      box-shadow: none;
      align-items: flex-start;
      padding-top: 30px;
    }

    ${Copyright} {
      flex: 1;
      font-size: 13px;
    }
  }

  @media only screen and (max-width: 550px) {
    text-align: center;

    > div {
      padding: 40px 0 30px;
    }
  }
`;

export default Footer;
