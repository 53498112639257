import React from "react";
import styled from "styled-components";
import hero from "../images/about-hero.jpg";
import census from "../images/about-census.jpg";
import pilot from "../images/about-pilot.jpg";

const About = () => (
  <Container>
    <HeroImage>
      <Image src={hero} />
    </HeroImage>
    <Hero>
      <TextWrapper>
        <h1>About BetterMedics</h1>
        <p>Your home healthcare companion.</p>
      </TextWrapper>
    </Hero>
    <Overview>
      <TextWrapper full>
        <h2>We're passionate about improving the lives of our patients.</h2>
        <PBorder>
          <p>
            Through remote patient monitoring and a 24/7 CareLine we fill the
            cracks of care. We exist between doctors visits, testing and
            procedures. We provide patients with support during moments of
            duress, illness, and uncertainty. Patients can call us if they feel
            worried, need support, experience issues with basic needs or feel
            ill. Our medical practice focuses on geriatric and palliative care,
            as we serve chronically ill, home-bound individuals.
          </p>
          <p>
            Each patient receives a BetterMedics kit that includes a Patient
            Telehealth Tablet, Pulse Oximeter, Thermometer and Blood Pressure
            Monitor. Patients can quickly connect to a CareLine Operator,
            monitor key vitals and receive a comprehensive virtual medical
            examination by a Physician. We are a new layer of care in the home,
            serving as both a support system and healthcare companion.
          </p>
        </PBorder>
      </TextWrapper>
    </Overview>
    <Census>
      <Row>
        <BlockImage left>
          <Image src={census} />
        </BlockImage>
        <TextWrapper vcenter>
          <h2>Our patient census and fee structure</h2>
          <PBorder>
            <p>
              BetterMedics is a telehealth medical practice, providing care to
              an initial patient census through a private research study. During
              the study our patient population receives medical services and
              technology for free. We are working with payer partners to
              designate the fee structure that will begin at the conclusion of
              the study. Our goal is to better understand the benefits to the
              patient, our place on their care team and impact to the managed
              care spectrum.
            </p>
          </PBorder>
        </TextWrapper>
      </Row>
    </Census>
    <Unique>
      <Row>
        <TextWrapper>
          <h2>How we are unique</h2>
          <p>
            Telehealth is a very competitive market. Our strategic
            differentiators include:
          </p>
          <ul>
            <li>
              <p>
                <b>Our Patient Census</b>The median age is 71, and we specialize
                in serving patients with comorbidities and mobility challenges.
                Telehealth needs to function differently when caring for this
                patient population. The BetterMedics CareLine offers immediate
                emotional support, we leverage real-time medical data and our
                physicians are passionate about geriatric care.
              </p>
            </li>
            <li>
              <p>
                <b>Our Scope of Care</b>Our telehealth practice goes beyond
                general medical services like addressing UTIs, cold/flu and skin
                conditions. We serve as an emotional and wellness companion that
                addresses the uncertainties derived from living with
                comorbidities, challenges that patients face with basic needs
                and advocacy.
              </p>
            </li>
            <li>
              <p>
                <b>Our Integration of Medical Devices</b>Collecting real-time
                patient data is important when caring for chronically ill
                individuals. Although remote patient monitoring is not new, our
                approach is unique and nimble. Over the next 5 years we will see
                an increase in patient generated data and traditional medical
                devices will include smart technology. Our approach allows us to
                create partnerships and leverage open APIs to build a catalog of
                integrations.
              </p>
            </li>
            <li>
              <p>
                <b>Our Focus on Interoperability</b>Treating complex patients
                means a heavier reliance on data- from medications to visit
                history. As we interact with other providers it's clear that the
                world of medical records is still fragmented, even with
                interoperability standards. Since our product follows the
                patient wherever they may be (home, assisted nursing facility,
                etc.) - collecting a patient's full medical history is
                important. For many patients it is the first time this has ever
                been attempted. We seek to create a cohesive record for
                patients, from all of their providers and facilities, that
                reflects their entire medical journey. In order to better
                understand our role in their care team, we will be pushing the
                bounds of information sharing and patient advocacy.
              </p>
            </li>
          </ul>
        </TextWrapper>
        <BlockImage>
          <Image src={pilot} />
        </BlockImage>
      </Row>
    </Unique>
  </Container>
);

const TextWrapper = styled.div`
  ${({ vcenter }) =>
    vcenter &&
    `
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: center;
	`}

  p {
    font-size: 19px;
    color: ${(props) => props.theme.textColors.primary};
  }
  h1,
  h2 {
    color: ${(props) => props.theme.colors.primary};
  }

  h2 {
    font-weight: 300;
  }

  @media (min-width: 993px) {
    text-align: left;
    flex-basis: ${(props) => (props.full ? "85%" : "50%")};
    max-width: ${(props) => (props.full ? "85%" : "50%")};
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (min-width: 80em) {
    padding-right: 60px;
    padding-bottom: 60px;
  }

  @media screen and (max-width: 992px) {
    padding: 0 20px !important;

    h1 {
      font-size: 36px;
    }
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 17px;
    }
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const HeroImage = styled.div`
  max-width: 45vw;
  max-height: 390px;

  position: absolute;
  top: 0;
  right: 0;

  border-radius: 0 0 0 80px;
  overflow: hidden;

  ${Image} {
    object-fit: cover;
  }

  @media screen and (max-width: 992px) {
    position: relative;
    max-width: 100%;
    max-height: auto;
    margin-left: 20px;
    height: auto;

    ${Image} {
      object-fit: contain;
    }
  }
`;

const BlockImage = styled.div`
  position: relative;
  height: 100%;

  ${({ left }) =>
    left &&
    `
		padding-right: 30px;
	`}

  @media (min-width: 993px) {
    flex-basis: 50%;
    max-width: 50%;
  }

  ${Image} {
    object-fit: cover;
  }

  @media screen and (max-width: 992px) {
    padding: 0 0 40px;
  }
`;

const Section = styled.section`
  position: relative;
  display: block;
  padding: 90px 0;
  overflow: hidden;

  @media screen and (max-width: 992px) {
    padding: 40px 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 0 auto;
  padding: 0 64px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    padding: 0;
  }
`;

const Hero = styled(Section)`
  p {
    font-size: 20px;
  }

  ${TextWrapper} {
    padding-left: 60px;

    h1 {
      color: ${(props) => props.theme.colors.primary};
      font-weight: 400;
    }
  }

  @media screen and (max-width: 992px) {
    padding-bottom: 0;
  }
`;

const Overview = styled(Section)`
  padding-top: 50px;
  padding-bottom: 40px;
`;

const Census = styled(Section)`
  padding-top: 80px;

  ${TextWrapper} {
    padding-bottom: 0;
  }

  @media (min-width: 80em) {
    ${TextWrapper} {
      padding-left: 120px;
      padding-right: 100px;
    }
  }

  @media (min-width: 60em) {
    ${TextWrapper} {
      padding-left: 40px;
      padding-right: 50px;
    }
  }
`;

const PBorder = styled.div`
  border-left: 1px solid ${(props) => props.theme.colors.secondary};
  padding: 8px 0 8px 20px;

  p:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 992px) {
    border-left: none;
    padding: 0;
  }
`;

const Unique = styled(Section)`
  ul {
    padding: 10px 0 0;
  }
  li {
    list-style: none;
    padding-bottom: 10px;

    p {
      font-size: 1em;
    }
    b {
      display: block;
      margin-bottom: 6px;
    }
  }

  ${BlockImage} {
    margin-top: 50px;
  }

  @media screen and (max-width: 992px) {
    ${BlockImage} {
      order: -1;
    }
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  padding-bottom: 80px;
`;

export default About;
