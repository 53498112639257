import React from 'react';
import styled from 'styled-components';
import hero from '../images/careers-hero.jpg';
import candidates from '../images/careers-candidates.png';

const Careers = () => (
  <Container>
    <Hero>
      <Row>
        <TextWrapper>
          <h1>Careers at BetterMedics</h1>
          <p>Thanks for your interest in BetterMedics. We are launching a six-month private research study in order to test new healthcare delivery models within the home. The pilot census will begin with 150 home-bound patients, of which the median age is 71 and 85% have at least two chronic illnesses. We believe that a 24-hour care line approach will strengthen home-support, improve quality of life and avoid unnecessary ER visits.</p>
        </TextWrapper>
        <ImageWrapper>
          <HeroImage>
            <Image src={hero} />
          </HeroImage>
        </ImageWrapper>
      </Row>
    </Hero>
    <Candidates>
      <Row>
        <TextWrapper>
          <h2>Candidates</h2>
          <p>We are looking for individuals who are passionate about helping people and excited about innovation in healthcare.  Our virtual interdisciplinary care team will be employed or contracted on a six month basis, with the potential for extension.</p>
        </TextWrapper>
        <ImageWrapper>
          <CandidatesImage>
            <Image src={candidates} />
          </CandidatesImage>
        </ImageWrapper>
      </Row>
    </Candidates>
    <Jobs>
      <h1>Now Hiring For</h1>
      <p>Check out the positions below in order to apply through our website</p>
      <PositionsWrapper>
        <Position>
          <a href="/careers/care-line-operator">Care Line Operator</a>
          <label>Employee</label>
        </Position>
        <Position>
          <a href="/careers/physician-contractor">On-Call Telehealth Physician</a>
          <label>Contractor</label>
        </Position>
      </PositionsWrapper>
      <p>Email <a href="mailto:HR@bettermedics.org">HR@bettermedics.org</a> with any questions. </p>
    </Jobs>
  </Container>
);

const TextWrapper = styled.div`
  text-align: center;

  @media (min-width: 60em) {
    text-align: left;
    flex-basis: 50%;
    max-width: 50%;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: 80em) {
    flex-basis: 50%;
    max-width: 50%;
    padding-right: 60px;
    padding-bottom: 60px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;

  @media (min-width: 60em) {
    flex-basis: 50%;
    max-width: 50%;
    padding-left: 60px;
  }

  @media (min-width: 80em) {
    flex-basis: 50%;
    max-width: 50%;
  }
`;

const HeroImage = styled.div`
  height: auto;
  max-width: 45vw;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const Section = styled.section`
  position: relative;
  display: block;
  padding: 120px 0;
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 0 auto;
  max-width: 1440px;
  width: calc(100% - 64px);
  padding: 0 64px;

  @media (max-width: 59.9375em) {
    margin: 0 auto;
    max-width: 500px;
  }
`;

const Hero = styled(Section)`
  min-height: 40vh;

  p {
    font-size: 20px;
  }
`;

const CandidatesImage = styled.div`
  height: auto;
  max-width: 30vw;

  @media (max-width: 59.9375em) {
    max-width: 100%;
    margin-top: 36px;
  }
`;

const Candidates = styled(Section)`
  background-color: ${props => props.theme.backgroundColors.secondary};

  @media (min-width: 80em) {
    ${TextWrapper} {
      padding-left: 120px;
      padding-right: 100px;
    }
  }

  @media (min-width: 60em) {
    ${TextWrapper} {
      padding-left: 40px;
      padding-right: 50px;
    }
  }

  ${Row} {
    align-items: center;
  }

  h2, p {
    color: white;
  }

  p {
    font-size: 19px;
  }
`;

const Jobs = styled(Section)`
  text-align: center;

  h1 {
    margin-bottom: 24px;
  }
  p a {
    color: ${props => props.theme.textColors.primary};
  }
`;

const PositionsWrapper = styled.div`
  margin: 36px 0;
`;

const Position = styled.span`
  display: block;
  padding: 20px 0;

  a {
    color: ${props => props.theme.colors.primary};
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }
  label {
    display: block;
    color: #999faf;
    font-size: 17px;
    margin-top: 5px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;


export default Careers;
