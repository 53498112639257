import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./index.css";
import AboutPage from "./pages/About";
import CareersPage from "./pages/Careers";
import Contact from "./pages/Contact";
import HIPAAComplaintsPage from "./pages/HIPAAComplaints";
import HomePage from "./pages/Home";
import ErrorPage from "./pages/404";
import InformedConsentPage from "./pages/InformedConsent";
import NonDiscriminationPage from "./pages/NonDiscrimination";
import PersonalHealthDisclosure from "./pages/PersonalHealthDisclosure";
import PositionPage from "./pages/Position";
import PrivacyPage from "./pages/Privacy";
import PrivacyPracticesPage from "./pages/PrivacyPractices";
import SupportPage from "./pages/Support";
import TermsPage from "./pages/Terms";
import CalendlyPage from "./pages/Calendly";
import InteriorLayout from "./layouts/InteriorLayout";
import Theme from "./Theme";

ReactDOM.render(
  <React.StrictMode>
    <Theme>
      <Router>
        <Switch>
          <Route exact path="/careers/:id">
            <InteriorLayout>
              <PositionPage />
            </InteriorLayout>
          </Route>
          <Route exact path="/contact">
            <InteriorLayout>
              <Contact />
            </InteriorLayout>
          </Route>
          <Route exact path="/about">
            <InteriorLayout>
              <AboutPage />
            </InteriorLayout>
          </Route>
          <Route exact path="/careers">
            <InteriorLayout>
              <CareersPage />
            </InteriorLayout>
          </Route>
          <Route exact path="/privacy">
            <InteriorLayout>
              <PrivacyPage />
            </InteriorLayout>
          </Route>
          <Route exact path="/privacy-practices">
            <InteriorLayout>
              <PrivacyPracticesPage />
            </InteriorLayout>
          </Route>
          <Route exact path="/informed-consent">
            <InteriorLayout>
              <InformedConsentPage />
            </InteriorLayout>
          </Route>
          <Route exact path="/terms">
            <InteriorLayout>
              <TermsPage />
            </InteriorLayout>
          </Route>
          <Route exact path="/notice-of-non-discrimination">
            <InteriorLayout>
              <NonDiscriminationPage />
            </InteriorLayout>
          </Route>
          <Route
            exact
            path="/notice-of-personal-and-health-information-disclosure"
          >
            <InteriorLayout>
              <PersonalHealthDisclosure />
            </InteriorLayout>
          </Route>
          <Route exact path="/HIPAA-complaints">
            <InteriorLayout>
              <HIPAAComplaintsPage />
            </InteriorLayout>
          </Route>
          <Route exact path="/support">
            <InteriorLayout>
              <SupportPage />
            </InteriorLayout>
          </Route>
          <Route exact path="/onboarding">
            <CalendlyPage />
          </Route>
          <Route exact path="/404">
            <ErrorPage />
          </Route>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route
            render={({ location }) => (
              <Redirect to={{ pathname: "/404", state: { from: location } }} />
            )}
          />
        </Switch>
      </Router>
    </Theme>
  </React.StrictMode>,
  document.getElementById("root")
);
