import hero from "../images/bettermedics-hero.jpg";
import logo from "../images/bettermedics-logo.png";

import React from "react";
import styled from "styled-components";

import Footer from "../components/Footer";

const Home = () => (
  <Page>
    <Container>
      <LeftColumn>
        <Logo src={logo} />
        <AuxMenu>
          <a href="/about">About Us</a>
          <a href="/careers">Careers</a>
          <a href="/contact">Contact Us</a>
        </AuxMenu>
        <Copy>
          <h1>
            Remote Patient
            <br />
            Monitoring Telehealth
          </h1>
          <p>
            BetterMedics is a driver of innovative home care for our nation’s
            most vulnerable population. We exist at the intersection of
            cutting-edge technology and compassionate healthcare.
          </p>
          <Button href="/about">Learn More</Button>
        </Copy>
      </LeftColumn>
      <RightColumn src={hero} />
    </Container>
    <Footer mode="home" />
  </Page>
);

const Copy = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const AuxMenu = styled.div`
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 5;
  display: flex;
  flex-direction: row;

  a {
    color: white;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    padding: 6px 10px;
    margin: 0 15px;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    letter-spacing: 0.5px;
  }

  @media only screen and (max-width: 1080px) {
    right: 15px;

    a {
      margin: 0 8px;
    }
  }

  @media only screen and (max-width: 920px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.backgroundColors.lightAlt};
    justify-content: flex-end;
    padding: 4px 0;

    a {
      color: ${(props) => props.theme.textColors.secondary};
      font-size: 14px;
      font-weight: normal;
      padding: 10px;
      margin: 0 10px;
    }
  }

  @media only screen and (max-width: 768px) {
    a {
      font-size: 15px;
    }
  }
`;

const Button = styled.a`
  display: block;
  width: 180px;
  height: 48px;
  line-height: 48px;
  text-decoration: none;
  background: ${(props) =>
    `linear-gradient(215deg, ${props.theme.colors.secondary}, ${props.theme.colors.primary})`};
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  color: white;
  letter-spacing: 0.35px;
  margin-top: 24px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: ${(props) =>
      `linear-gradient(195deg, ${props.theme.colors.secondary}, ${props.theme.colors.primary})`};
  }

  @media only screen and (max-width: 550px) {
    align-self: center;
  }
`;

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
`;

const RightColumn = styled(Column)`
  position: relative;
  background: ${(props) =>
    `linear-gradient(315deg, ${props.theme.colors.tertiary}, ${props.theme.colors.secondary})`};

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: block;
    background-image: ${(props) => `url(${props.src})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    opacity: 0.2;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: block;

    width: 0;
    height: 0;
    border-bottom: 2000px solid transparent;
    border-left: 150px solid white;
  }

  @media only screen and (max-width: 920px) {
    display: none;
  }
`;

const LeftColumn = styled(Column)`
  background-color: white;
  padding: 20px 120px 80px;
  overflow-y: scroll;

  @media only screen and (max-width: 920px) {
    width: 100%;
    padding: 20px 80px;

    &::after {
      display: none;
    }
  }

  @media only screen and (max-width: 550px) {
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 30px;

    &::after {
      display: none;
    }
  }
`;

const Logo = styled.img`
  width: 250px;
  margin-top: 40px;
  margin-bottom: 12px;
  transform: translateZ(0);

  @media only screen and (max-width: 920px) {
    margin-top: 60px;
  }

  @media only screen and (max-width: 550px) {
    margin-bottom: 30px;
    width: 200px;
    align-self: center;
  }
`;

export default Home;
